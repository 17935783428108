import axios from '../axios'
const transformData = dataArray => {
  return dataArray.reduce((acc, person) => {
    const taskIds = person.tasks_intersection.map(task => task.id)
    return acc.concat(taskIds)
  }, [])
}
const appendParams = (rootState, data, extraParams) => {
  if (!data) return
  const params = new URLSearchParams()
  const assignerResources = localStorage.getItem('assignedResources')
  let parsedResources = null
  if (assignerResources && extraParams) {
    try {
      parsedResources = JSON.parse(assignerResources)
      transformData(parsedResources).forEach((id, index) => {
        params.append(`intersection_assigned_tasks[${index}]`, id)
      })
    } catch (error) {
      console.error(error)
    }
  }
  data.projects.forEach((id, index) => {
    params.append(`projects[${index}]`, id)
  })
  const teams = rootState.Auth.user.teams
  teams.forEach((team, index) => {
    params.append(`teams_id[${index}]`, team.id)
  })
  if (data.start_date && data.end_date) {
    params.append('start_date', data.start_date)
    params.append('end_date', data.end_date)
  }
  if (data.parent_id) params.append('parent_id', data.parent_id)
  if (data.gantt) {
    params.append('gantt', data.gantt)
  }

  return params
}
const updateGanttList = (state, newTasks) => {
  const updatedGanttList = [...state.ganttList.data]
  newTasks.forEach(newTask => {
    const existingTaskIndex = updatedGanttList.findIndex(task => task.id === newTask.id)

    if (existingTaskIndex !== -1) {
      updatedGanttList[existingTaskIndex] = {
        ...updatedGanttList[existingTaskIndex],
        end_date: newTask.end_date,
        planned_end: newTask.planned_end,
        planned_start: newTask.planned_start,
        start_date: newTask.start_date,
        fact_end_date: newTask.fact_end_date,
        fact_start_date: newTask.fact_start_date,
        status: newTask.status,
      }
    } else {
      updatedGanttList.push(newTask)
    }
  })
  return updatedGanttList
}
export default {
  async ganttList({ rootState, commit }, data) {
    try {
      if (!data.projects) return
      const params = appendParams(rootState, data)
      const response = await axios.get(`/list/tasks/search?${params.toString()}`)
      commit('setGanttList', { list: response.tasks, isOnlyTasks: false })
      return response
    } catch (error) {
      console.error(error)
    }
  },
  async ganttGetResourcesAssigned({ commit }, projectId) {
    try {
      const response = await axios.get(`projects/${projectId}/list_intersection_assigned`)
      commit('setResourcesAssigned', response)
      return response
    } catch (error) {
      console.error(error)
    }
  },
  async ganttSubtasksList({ rootState, commit }, data) {
    try {
      const params = appendParams(rootState, data)
      const response = await axios.get(`/gantt/tasks?${params.toString()}`)
      commit('setGanttSubtasksList', response.tasks.data)
      return response
    } catch (error) {
      console.error(error)
    }
  },
  async ganttCommit({ rootState, commit }, data) {
    try {
      const params = appendParams(rootState, data)
      const response = await axios.post(`/gantt/draft/commit?${params.toString()}`)
      if (response) {
        commit('setHasTasksForUpdate', false)
        commit('setGanttList', { list: response.tasks, isOnlyTasks: true })
      }
      return true
    } catch (error) {
      console.error(error)
    }
  },

  async ganttUpdate({ dispatch, commit }, payload) {
    try {
      await axios.post('/gantt/draft/update', payload)
      await dispatch('ganttListTaskBranch', payload.id)
      commit('setHasTasksForUpdate', true)
      return true
    } catch (error) {
      console.error(error)
    }
  },

  async ganttAllTaskUpdate({ dispatch, commit }, payload) {
    try {
      await axios.post('/gantt/drafts/create', payload)
      commit('setHasTasksForUpdate', true)
      await dispatch('ganttListTaskBranch', payload.id)
      commit('updateDatesProjectWithTasks', '')
      return true
    } catch (error) {
      console.error(error)
    }
  },
  async ganttFixPlan({ rootState, commit }, data) {
    try {
      const params = appendParams(rootState, data)
      const response = await axios.post(`/gantt/set-planned-date?${params.toString()}`)
      commit('setGanttList', { list: response.tasks, isOnlyTasks: true })
      return response
    } catch (error) {
      console.error(error)
    }
  },

  async ganttRollback({ rootState, commit }, data) {
    try {
      const params = appendParams(rootState, data)
      const response = await axios.post(`/gantt/draft/rollback?${params.toString()}`)
      commit('setGanttList', { list: response.tasks, isOnlyTasks: true })
      return response
    } catch (error) {
      console.error('rollback', error)
    }
  },

  async createLink({ commit }, payload) {
    try {
      const res = await axios.post('/gantt/links', payload)
      commit('setNewLink', res)
      commit('setHasTasksForUpdate', true)
      return res
    } catch (error) {
      console.error(error)
    }
  },

  async deletedLink({ commit }, id) {
    try {
      await axios.delete(`gantt/links/${id}?is_draft=${1}`)
      commit('setDeleteLink', id)
      commit('setHasTasksForUpdate', true)
      return true
    } catch (e) {
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },

  async ganttAddTask({ dispatch, commit }, data) {
    try {
      const response = await axios.post('tasks', data)
      await dispatch('ganttListTaskBranch', response.id)
      commit('setGanttNewTask', response)
      return response
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
      return false
    }
  },

  async ganttDeletedTask({ commit }, taskId) {
    try {
      const response = await axios.delete(`tasks/${taskId}?is_draft=${1}`)
      commit('setHasTasksForUpdate', true)
      const payload = { taskId, response }
      commit('setDeleteTask', payload)
      return response
    } catch (e) {
      commit('Notify/setError', 'При удалении рабочего задания произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async ganttListTaskBranch({ rootState, state, commit }, id) {
    if (!id) return
    const params = new URLSearchParams()
    const teams = rootState.Auth.user.teams
    teams.forEach((team, index) => {
      params.append(`teams_id[${index}]`, team.id)
    })
    try {
      const response = await axios.get(`/get_list_task_branch?task_id=${id}&gantt=1&${params.toString()}`)
      if (response) {
        const updatedGanttList = updateGanttList(state, response)
        commit('setGanttList', { list: updatedGanttList, isOnlyTasks: true })
        return response
      }
    } catch (error) {
      console.error(error)
    }
  },
  async ganttPastTask({ commit }, payload) {
    const { copyTaskId, type, isProject, pastTaskId, projectId } = payload

    try {
      const res = await axios.post(`gantt/${copyTaskId}/clone`, {
        with_subtask: !!type,
        project_id: projectId,
        state_id: isProject ? undefined : pastTaskId,
        is_draft: 1,
      })

      if (res) {
        commit('setCopyTasks', res.tasks)
      }
      return res
    } catch (e) {
      commit('Notify/setError', 'При копировании произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async planHistory({ commit }, payload) {
    try {
      await axios.post('repairs/metrics/set_plan_history', payload)
      return true
    } catch (e) {
      commit('Notify/setError', 'При сохранении фиксированного плана произошла ошибка. Попробуйте позднее', {
        root: true,
      })
    }
  },
}
