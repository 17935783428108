import axios from '../axios'

async function downloadTask(rootState, params) {
  const filterResponsibleIsChecked = Number(rootState.Task.filterResponsibleIsChecked)
  const teams = rootState.Auth.user.teams
  const teamsParam = {}
  teams.forEach((team, index) => {
    teamsParam[`filter[teams][${index}]`] = team.id
  })
  const needShowState = !filterResponsibleIsChecked && !params?.assigned && !params?.responsible
  const projectType = Number(rootState.Project.projectType === 'Проекты АПР')
  const filters = {
    ...teamsParam,
    'filter[state]': Number(needShowState),
    'sort[column]': params?.sortValue ?? 'created_at',
    'sort[type]': params?.sortType ?? 'desc',
    'filter[statuses][0]': '9a76119c-837d-4813-a2f1-0fc68339076b', // open
    'filter[statuses][1]': '9a76119c-847e-4702-87e5-4a959b297820', // progress
    'filter[statuses][2]': '9a76119c-8667-4aed-b9dd-569738af83b0', // done
    'filter[projects][0]': params?.projectId,
    'filter[my_tasks_today]': filterResponsibleIsChecked || undefined,
    'filter[assigned][0]': params?.assigned,
    'filter[responsible][0]': params?.responsible,
    'filter[kanban]': params?.isKanban,
    gantt: 0,
    search: params?.search,
    page: params?.currentPage,
  }

  if (projectType) {
    filters['filter[is_plan_repair]'] = projectType
  }

  const response = await axios.get('/list/tasks/search', {
    params: filters,
  })
  return [response, response.data.map(task => ({ ...task, parent: null }))]
}

// Получение списка задач
export default {
  async downloadTaskList({ rootState, commit }, params) {
    try {
      commit('setTaskLoading', true)
      const [response, data] = await downloadTask(rootState, params)
      commit('setTaskList', data)
      commit('setTaskPageData', response)
    } catch (error) {
      console.error('error', error)
      if (!error.logout) {
        commit('Notify/setError', 'При загрузке списка рабочих заданий произошла ошибка. Попробуйте позже.', {
          root: true,
        })
      }
    } finally {
      commit('setTaskLoading', false)
    }
  },
  async downloadKanbanTaskList({ rootState, commit }, params) {
    try {
      const [response, data] = await downloadTask(rootState, params)
      const payload = { data, hasSearch: params.search }
      commit('setKanbanTaskList', payload)
      commit('setTaskPageDataForScroll', response)
    } catch (error) {
      console.error('error', error)
      if (!error.logout) {
        commit('Notify/setError', 'При загрузке списка рабочих заданий произошла ошибка. Попробуйте позже.', {
          root: true,
        })
      }
    } finally {
      commit('setTaskLoading', false)
    }
  },
  async downloadSubtaskList({ commit }, id) {
    try {
      const response = (await axios.get(`/task/${id}/subtasks/list`)) || []
      const data = response.map(task => ({ ...task, parent: id }))
      commit('setSubTaskList', data)
      return data
    } catch (error) {
      if (!error.logout)
        commit('Notify/setError', 'При загрузке списка подзадач произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async updateTaskTree(
    { rootState, commit },
    { node, isProfileTasks = false, profileParams = { assigned: null, responsible: null } },
  ) {
    if (!node.state_id) {
      const params = {
        projectId: rootState.Project.selectedProjectId,
        currentPage: rootState.Task.currentPage,
        assigned: isProfileTasks ? profileParams.assigned : null,
        responsible: isProfileTasks ? profileParams.responsible : null,
      }
      const [response, data] = await downloadTask(rootState, params)
      commit('updateTaskList', data)
      commit('setTaskPageData', response)
      return data
    }
    if (node.state_id || node.parent) {
      const data = await axios.get(`/get_list_task_branch?task_id=${node.id}`)
      if (node.state_id) rootState.Task.taskList = rootState.Task.taskList.filter(x => x.parent !== node.state_id)
      commit('updateSubTaskList', data)
      return data
    }
  },
  // Получить задачу по id
  async getCurrentTask(_, taskId) {
    try {
      return await axios.get(`/tasks/${taskId}`)
    } catch (error) {
      console.error(error)
    }
  },
  async updateDeleteTask({ rootState, commit }, payload) {
    let { node, isProfileTasks, profileParams } = payload
    const checkRootNodes = () => {
      const parentNode = rootState.Task.taskList.find(task => task.id === node.state_id)
      const childrenCount = rootState.Task.taskList.filter(task => task.parent === node.state_id)?.length
      return childrenCount === 1 && parentNode?.parent === null
    }
    if (!node.parent || checkRootNodes()) {
      const params = {
        projectId: rootState.Project.selectedProjectId,
        currentPage: rootState.Task.currentPage,
        assigned: isProfileTasks ? profileParams.assigned : null,
        responsible: isProfileTasks ? profileParams.responsible : null,
      }
      const [response, data] = await downloadTask(rootState, params)
      commit('updateTaskList', data)
      commit('setTaskPageData', response)
      return
    }
    if (node.parent) {
      rootState.Task.taskList = rootState.Task.taskList.filter(task => task.id !== node.id)
      const nextNode = rootState.Task.taskList.find(task => task.parent === node.parent)
      if (nextNode) node = nextNode
      if (!nextNode) node = rootState.Task.taskList.find(task => task.id === node.parent)
      const data = await axios.get(`/get_list_task_branch?task_id=${node.id}`)
      commit('updateSubTaskList', data)
    }
  },
  // Удаление задач из списка
  async deletedTask({ dispatch, commit }, payload) {
    const { task, isProfileTasks, profileParams } = payload
    try {
      commit('setTaskDeleteLoading', true)
      await axios.delete(`tasks/${task.id}`)
      await dispatch('updateDeleteTask', { node: task, isProfileTasks, profileParams })
      // commit('deleteTask', task)
    } catch (e) {
      commit('Notify/setError', 'При удалении рабочего задания произошла ошибка. Попробуйте позже.', { root: true })
    } finally {
      commit('setTaskDeleteLoading', false)
    }
  },
  // Получения списка задач, которые находятся на критическом пути
  async getCriticalPathTasks(_, projectId) {
    try {
      return await axios.get('gantt/get_list_task_on_critical_path', {
        params: {
          'projects[0]': projectId,
        },
      })
    } catch (error) {
      console.error(error)
    }
  },
  async taskPause(
    { dispatch, commit },
    { id, isProfileTasks = false, profileParams = { assigned: null, responsible: null } },
  ) {
    try {
      const response = await axios.put(`tasks/${id}/pause`)
      await dispatch('Task/updateTaskTree', { node: response, isProfileTasks, profileParams }, { root: true })
      return response
    } catch (error) {
      console.error(error)
      commit('Notify/setError', 'Не удалось поставить на паузу. Попробуйте позже.', { root: true })
    }
  },
  async taskPlay(
    { dispatch, commit },
    { id, isProfileTasks = false, profileParams = { assigned: null, responsible: null } },
  ) {
    try {
      const response = await axios.put(`tasks/${id}/play`)
      await dispatch('Task/updateTaskTree', { node: response, isProfileTasks, profileParams }, { root: true })
      return response
    } catch (error) {
      console.error(error)
      commit('Notify/setError', 'Не удалось снять с паузы. Попробуйте позже.', { root: true })
    }
  },
  // Редактирование задачи
  async editTask({ commit }, payload) {
    try {
      const { taskId, data } = payload
      const params = {
        name: data?.name, // Название задачи (string | undefined)
        description: data?.description, // Описание задачи(string | undefined)
        instrument: data?.instrument_types, // Массив UUID инструментов (string[] | undefined)
        responsible: data?.responsible?.map(item => item.id) ?? undefined, // Массив UUID ответственных пользователей (string[] | undefined)
        assigned: data?.assigned, // Массив UUID назначенных пользователей (string[] | undefined)
        position_assigned: data?.position_assigned, // Массив объектов с информацией о назначенных позициях (Array<{}> | undefined)
        files: data?.files, // Массив UUID файлов (string[] | undefined)
        position: data?.position, // Массив объектов позиций
        warehouse: data?.warehouse, // Массив UUID складов(string[] | undefined)
        teams: data?.teams?.map(team => team.id) ?? undefined, // Массив UUID команд (string[] | undefined)
        work_zone_id: data?.work_zone_id, // UUID рабочей зоны (string | undefined)
        state_id: data?.state_id, // UUID состояния задачи (string | undefined)
        location_id: data?.location_id, // UUID локации (string | undefined)
        project_id: data?.project?.id, // UUID проекта (string | undefined)
        priority: data?.priority, // Приоритет задачи (number | undefined)
        start_date: data?.start_date, // Дата начала (string | Date | undefined)
        plan_date: data?.plan_date, // Плановая дата (string | Date | undefined)
        deadline: data?.deadline, // Крайний срок (string | Date | undefined)
        time_to_complete: data?.time_to_complete, // Время на выполнение (number | undefined)
        time_to_complete_sec: data?.time_to_complete_sec, // Время на выполнение в секундах (number | undefined)
        target: data?.target, // Цель задачи (string | undefined)
        repair_object_id: data?.repair_object_id, // UUID объекта ремонта (string | undefined)
        copy: data?.copy, // Флаг копирования (boolean)
        // equipment_id: payload?.equipment_id, // UUID оборудования (number | undefined | null)
        // status_id: payload?.status_id, // UUID статуса (string | undefined)
      }

      const response = await axios.put(`tasks/${taskId}`, {
        ...params,
      })

      return response
    } catch (error) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
      console.error(error)
    }
  },
  // Редактировать оборудование задачи
  async editEquipment({ commit }, payload) {
    const { taskId, data } = payload
    try {
      const response = await axios.put(`task/${taskId}/update/equipment`, data)
      return response
    } catch (error) {
      console.error(error)
      commit('Notify/setError', 'При редактировании оборудования произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  // Смена статуса задачи
  async editStatus({ commit }, payload) {
    const { taskId, data } = payload
    try {
      const response = await axios.put(`task/${taskId}/change-status`, data)
      return response[taskId] // Бэк возвращает объект задач с изменёнными статусами, где ключи id задач
    } catch (error) {
      console.error(error)
      if (error.handled) return
      commit('Notify/setError', 'При смене статуса произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  // Получение списка оборудования
  async downloadEquipmentList({ commit }) {
    try {
      const response = await axios.get('repairs/equipment/list')
      return response.data
    } catch (error) {
      console.error(error)
      if (!error.logout) {
        commit('Notify/setError', 'При загрузке списка оборудования произошла ошибка. Попробуйте позже.', {
          root: true,
        })
      }
    }
  },
  // Проверка для перевода задачи в статус "готово" при завершение суммарной
  async checkBranchBeforeCloseTask({ commit }, taskId) {
    try {
      const params = {
        task_id: taskId,
      }

      const response = await axios.post('check_branch_before_close_task', {
        ...params,
      })

      if (response.data === false) {
        return response.data
      } else {
        return response
      }
    } catch (error) {
      console.error(error)
      if (!error.logout) {
        commit('Notify/setError', 'При проверке статуса суммарной задаче произола ошибка. Попробуйте позже.', {
          root: true,
        })
      }
    }
  },
}
