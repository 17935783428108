import store from '../../store'

const Layout = () => import('../../layouts/Layout')
const AuthLayout = () => import('../../layouts/AuthLayout')
/* Authentic View */
const SignIn = () => import('../../components/views/AuthPages/Default/SignIn')
const SignOut = () => import('../../components/views/AuthPages/Default/SignOut')
const SignUp = () => import('../../components/views/AuthPages/Default/SignUp')
const RecoverPassword1 = () => import('../../components/views/AuthPages/Default/RecoverPassword1')
const LockScreen = () => import('../../components/views/AuthPages/Default/LockScreen')
const ConfirmMail = () => import('../../components/views/AuthPages/Default/ConfirmMail')

const beforeEnter = (to, from, next) => {
  const currentUser = store.getters['Auth/user']
  if (currentUser) return next('/')
  return next()
}

export const getStartPage = () => {
  return '/profile'
}

const childRoutes = () => [
  {
    path: '/profile',
    name: 'profile',
    title: 'Мой профиль',
    icon: 'user',
    permissionsPath: '/profile',
    meta: {
      auth: true,
      name: 'Profile',
      sidebar: true,
    },
    component: () => import('@/views/Profile.vue'),
  },
  {
    path: '',
    permissionsPath: '',
    name: '',
    icon: 'repairs',
    title: 'Ремонты',
    meta: {
      auth: true,
      sidebar: true,
    },
    children: [
      {
        path: '/repairs/',
        name: 'repairs',
        title: 'Ремонты',
        icon: 'repairs',
        permissionsPath: '/repair',
        meta: {
          auth: true,
          name: 'Repairs',
          sidebar: true,
        },
      },
      // {
      //   path: '/repairs/objects',
      //   name: 'repairs-objects',
      //   permissionsPath: '/repair-object',
      //   title: 'Объекты ремонтов',
      //   icon: 'repair-objects',
      //   meta: {
      //     auth: true,
      //     name: 'RepairsObjects',
      //     sidebar: true
      //   }
      // },
      {
        path: '/equipment',
        name: 'equipment',
        permissionsPath: '/equipment',
        title: 'Оборудование',
        icon: 'videozones',
        meta: {
          auth: true,
          name: 'Equipment',
          sidebar: true,
        },
        components: {
          default: () => import('@/components/views/Pages/endpoints/EquipmentEndpoint.vue'),
          'header-actions': () => import('@/components/views/Pages/endpoints/HeaderActionsEndpoint'),
        },
      },
    ],
  },
  {
    path: '/projects-and-tasks',
    name: 'ProjectsAndTasks',
    title: 'Проекты и задачи',
    icon: 'projects',
    permissionsPath: '/projects-and-tasks',
    meta: {
      auth: true,
      name: 'ProjectsAndTasks',
      sidebar: true,
    },
    props: true,
    component: () => import('@/views/ProjectsAndTasks'),
  },
  {
    path: '/my-tasks-today',
    name: 'my-tasks-today',
    permissionsPath: '/my-tasks-today',
    title: 'Мои рабочие задания',
    icon: 'tasks_today',
    meta: {
      auth: true,
      name: 'MyTasksToday',
      sidebar: true,
    },
    components: {
      default: () => import('@/views/MyTasksToday.vue'),
      'header-actions': () => import('@/components/views/Pages/endpoints/HeaderActionsEndpoint'),
    },
  },
  {
    path: '',
    permissionsPath: '',
    title: 'Инструменты',
    name: '',
    icon: 'instruments',
    meta: {
      auth: true,
      sidebar: true,
    },
    children: [
      {
        path: '/instruments',
        permissionsPath: '/instrument',
        name: 'instruments',
        title: 'Инструменты',
        icon: 'repairs',
        meta: {
          auth: true,
          name: 'Instruments',
          sidebar: true,
        },
        components: {
          default: () => import('@/components/views/Pages/endpoints/InstrumentsEndpoint'),
          'header-actions': () => import('@/components/views/Pages/endpoints/HeaderActionsEndpoint'),
        },
      },
      {
        path: '/warehouses',
        permissionsPath: '/warehouse',
        name: 'warehouses',
        title: 'Инструменты на складе',
        icon: 'warehouses',
        meta: {
          auth: true,
          name: 'Warehouses',
          sidebar: true,
        },
        components: {
          default: () => import('@/components/views/Pages/endpoints/WarehouseEndpoint'),
          'header-actions': () => import('@/components/views/Pages/endpoints/HeaderActionsEndpoint'),
        },
      },
      // {
      //   path: '/workshop',
      //   permissionsPath: '/workshop',
      //   name: 'workshop',
      //   title: 'Мастерская',
      //   icon: 'workshop',
      //   meta: {
      //     auth: true,
      //     name: 'InstrumentsForeman',
      //     sidebar: true,
      //   },
      //   components: {
      //     default: () => import('@/components/views/Pages/endpoints/InstrumentsForemanEndpoint'),
      //     'header-actions': () => import('@/components/views/Pages/endpoints/HeaderActionsEndpoint'),
      //   },
      // },
      {
        path: '/instrument-work-zone',
        permissionsPath: '/instrument-work-zone',
        name: 'instrument-work-zone',
        title: 'Зоны проведения работ',
        icon: 'instrument-work-zone',
        meta: {
          auth: true,
          name: 'InstrumentsWorkZone',
          sidebar: true,
        },
        components: {
          default: () => import('@/components/views/Pages/endpoints/InstrumentsWorkZoneEndpoint'),
          'header-actions': () => import('@/components/views/Pages/endpoints/HeaderActionsEndpoint'),
        },
      },
    ],
  },
  // {
  //   path: '',
  //   permissionsPath: '',
  //   name: '',
  //   title: 'Заказ',
  //   icon: 'order',
  //   meta: {
  //     auth: true,
  //     sidebar: true,
  //   },
  //   children: [
  //     {
  //       path: '/instrument-preorder',
  //       permissionsPath: '/instrument-preorder',
  //       name: 'instrument-preorder',
  //       title: 'Предзаказ',
  //       icon: 'preorder',
  //       meta: {
  //         auth: true,
  //         name: 'Preorder',
  //         sidebar: true,
  //       },
  //       components: {
  //         default: () => import('@/views/Preorder.vue'),
  //         'header-actions': () => import('@/components/views/Pages/endpoints/HeaderActionsEndpoint'),
  //       },
  //     },
  //     {
  //       path: '/instrument-order',
  //       permissionsPath: '/instrument-order',
  //       name: 'orders',
  //       title: 'Заказы МТР',
  //       icon: 'instrument-order',
  //       meta: {
  //         auth: true,
  //         name: 'Orders',
  //         sidebar: true,
  //       },
  //       component: () => import('@/components/views/Pages/endpoints/OrdersEndpoint'),
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   permissionsPath: '',
  //   name: '',
  //   title: 'Утиль',
  //   icon: 'trash',
  //   meta: {
  //     auth: true,
  //     sidebar: true
  //   },
  //   children: [
  //     {
  //       path: '/write-off',
  //       permissionsPath: '/write-off',
  //       title: 'Списать МТР',
  //       name: 'write-off',
  //       icon: 'times-circle',
  //       meta: {
  //         auth: true,
  //         name: 'WriteOff',
  //         sidebar: true
  //       },
  //       components: {
  //         default: () => import('@/views/WriteOff.vue'),
  //         'header-actions': () => import('@/components/views/Pages/endpoints/HeaderActionsEndpoint')
  //       }
  //     },
  //     {
  //       path: '/instrument-document',
  //       permissionsPath: '/instrument-document',
  //       name: 'decommissioned',
  //       title: 'Списанные МТР',
  //       icon: 'trash-alt',
  //       meta: {
  //         auth: true,
  //         name: 'Decommissioned',
  //         sidebar: true
  //       },
  //       component: () => import('@/views/Decommissioned.vue')
  //     }
  //   ]
  // },
  {
    path: '/modules',
    permissionsPath: '/module',
    name: 'modules',
    title: 'Модули',
    meta: {
      auth: true,
      name: 'Modules',
      sidebar: true,
    },
    icon: 'modules',
    components: {
      default: () => import('@/components/views/Pages/endpoints/ModulesEndpoint'),
      'header-actions': () => import('@/components/views/Pages/endpoints/HeaderActionsEndpoint'),
    },
  },
  // {
  //   path: '/instrument-history',
  //   permissionsPath: '/instrument-history',
  //   name: 'instrument-history',
  //   title: 'История применения',
  //   meta: {
  //     auth: true,
  //     name: 'History',
  //     sidebar: true,
  //   },
  //   icon: 'instrument-history',
  //   component: () => import('@/views/History.vue'),
  // },
  // {
  //   path: '/status-history',
  //   permissionsPath: '/status-history',
  //   name: 'status-history',
  //   title: 'История статусов',
  //   meta: {
  //     auth: true,
  //     name: 'StatusHistory',
  //     sidebar: true,
  //   },
  //   icon: 'status-history',
  //   component: () => import('@/components/views/Pages/endpoints/StatusHistoryEndpoint'),
  // },
  // {
  //   path: '/pass',
  //   permissionsPath: '/pass',
  //   name: 'assign-rfid',
  //   title: 'Присвоить метку',
  //   meta: {
  //     auth: true,
  //     name: 'AssignRfid',
  //     sidebar: true,
  //   },
  //   icon: 'pass',
  //   component: () => import('@/components/views/Pages/endpoints/AssignRfidEndpoint'),
  // },
  // {
  //   path: '/assign-module',
  //   permissionsPath: '/assign-module',
  //   name: 'assign-module',
  //   title: 'Выдать МТР',
  //   meta: {
  //     auth: true,
  //     name: 'AssignModule',
  //     sidebar: true,
  //   },
  //   icon: 'assign-module',
  //   component: () => import('@/views/AssignModule.vue'),
  // },
  {
    path: '',
    permissionsPath: '',
    name: '',
    title: 'Пользователи',
    icon: 'users',
    meta: {
      auth: true,
      sidebar: true,
    },
    children: [
      {
        path: '/users',
        permissionsPath: '/user',
        name: 'users',
        title: 'Пользователи',
        icon: 'user',
        meta: {
          auth: true,
          name: 'Users',
          sidebar: true,
        },
        components: {
          default: () => import('@/components/views/Pages/endpoints/UsersEndpoint'),
          'header-actions': () => import('@/components/views/Pages/endpoints/HeaderActionsEndpoint'),
        },
      },
      {
        path: '/positions',
        permissionsPath: '/position',
        name: 'positions',
        title: 'Должности',
        icon: 'positions',
        meta: {
          auth: true,
          name: 'Positions',
          sidebar: true,
        },
        components: {
          default: () => import('@/components/views/Pages/endpoints/PositionsEndpoint'),
          'header-actions': () => import('@/components/views/Pages/endpoints/HeaderActionsEndpoint'),
        },
      },
      {
        path: '/roles',
        permissionsPath: '/role',
        name: 'roles',
        title: 'Роли',
        icon: 'roles',
        meta: {
          auth: true,
          name: 'Roles',
          sidebar: true,
        },
        components: {
          default: () => import('@/components/views/Pages/endpoints/RolesEndpoint'),
          'header-actions': () => import('@/components/views/Pages/endpoints/HeaderActionsEndpoint'),
        },
      },
      {
        path: '/teams',
        permissionsPath: '/team',
        name: 'teams',
        title: 'Команды',
        icon: 'teams',
        meta: {
          auth: true,
          name: 'Teams',
          sidebar: true,
        },
        components: {
          default: () => import('@/components/views/Pages/endpoints/TeamsEndpoint'),
          'header-actions': () => import('@/components/views/Pages/endpoints/HeaderActionsEndpoint'),
        },
      },
    ],
  },
  {
    path: '',
    permissionsPath: '',
    name: '',
    title: 'Зоны работ',
    icon: 'workzones',
    meta: {
      auth: true,
      sidebar: true,
    },
    children: [
      {
        path: '/work-zone',
        permissionsPath: '/work-zone',
        name: 'work-zone',
        title: 'Видео зоны',
        icon: 'videozones',
        meta: {
          auth: true,
          name: 'VideoZones',
          sidebar: true,
        },
        components: {
          default: () => import('@/views/VideoZones.vue'),
          'header-actions': () => import('@/components/views/Pages/endpoints/HeaderActionsEndpoint'),
        },
      },
      {
        path: '/video-source',
        permissionsPath: '/video-source',
        name: 'video-source',
        title: 'Источники видео',
        icon: 'videosources',
        meta: {
          auth: true,
          name: 'VideoSources',
          sidebar: true,
        },
        components: {
          default: () => import('@/views/VideoSources.vue'),
          'header-actions': () => import('@/components/views/Pages/endpoints/HeaderActionsEndpoint'),
        },
      },
      {
        path: '/video-wall',
        permissionsPath: '/video-wall',
        name: 'video-wall',
        title: 'Видео стена',
        icon: 'videowall',
        meta: {
          auth: true,
          name: 'VideoWall',
          sidebar: true,
        },
        components: {
          default: () => import('@/views/VideoWall.vue'),
          'header-actions': () => import('@/components/views/Pages/endpoints/HeaderActionsEndpoint'),
        },
      },
      {
        path: '/checkpoints',
        permissionsPath: '/module-checkpoint',
        name: 'checkpoint',
        title: 'Контрольные точки',
        meta: {
          auth: true,
          name: 'Checkpoint',
          sidebar: true,
          pageTitle: 'Контрольные точки',
        },
        icon: 'modules',
        components: {
          default: () => import('@/components/views/Pages/endpoints/CheckpointsEndpoint'),
          'header-actions': () => import('@/components/views/Pages/endpoints/HeaderActionsEndpoint'),
        },
      },
      {
        path: '/video-sync',
        name: 'VideoSync',
        title: 'Разметка видеозоны',
        icon: 'videozones',
        permissionsPath: '/video-sync',
        meta: {
          auth: true,
          name: 'VideoSync',
          sidebar: true,
        },
        props: true,
        component: () => import('@/views/VideoSync'),
      },
    ],
  },

  // {
  //   path: '/notifications',
  //   permissionsPath: '/notification',
  //   name: 'notifications',
  //   title: 'Уведомления',
  //   meta: {
  //     auth: true,
  //     name: 'Notifications',
  //     sidebar: true,
  //   },
  //   icon: 'notifications',
  //   component: () => import('@/components/views/Pages/endpoints/NotificationsEndpoint'),
  // },
  {
    path: '/warehouses-profile',
    permissionsPath: '/warehouses-profile',
    name: 'WarehousesProfile',
    component: () => import('../../components/views/Pages/Warehouses/WarehousesProfile'),
  },
]

const authChildRoutes = prop => [
  {
    path: '/sign-in',
    name: prop + '.sign-in',
    meta: { auth: false },
    component: SignIn,
    beforeEnter,
  },
  {
    path: 'sign-up',
    name: prop + '.sign-up',
    meta: { auth: false },
    component: SignUp,
    beforeEnter,
  },
  {
    path: '/sign-out',
    permissionsPath: '/sign-out',
    name: prop + '.sign-out',
    title: 'Выход',
    icon: 'logout',
    meta: {
      auth: true,
      name: 'Выход',
      sidebar: true,
    },
    component: SignOut,
  },
  {
    path: 'password-reset',
    name: prop + '.password-reset',
    meta: { auth: true },
    component: RecoverPassword1,
  },
  {
    path: 'lock-screen',
    name: prop + '.lock-screen',
    meta: { auth: true },
    component: LockScreen,
  },
  {
    path: 'confirm-mail',
    name: prop + '.confirm-mail',
    meta: { auth: true },
    component: ConfirmMail,
  },
]

export default [
  {
    path: '/',
    redirect: '/profile',
    component: Layout,
    children: childRoutes('main'),
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthLayout,
    meta: { auth: false },
    children: authChildRoutes('auth'),
  },
]
