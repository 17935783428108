import { cloneDeep } from 'lodash'
import { initTaskFilters } from '@/store/TaskList/index'
import { findParentTask } from '@/Utils/helpers'

export default {
  tasksWasDownload: (state, value) => (state.tasksWasDownload = value),
  taskHasUpdated: (state, value) => (state.taskHasUpdated = value),
  hasAddedTaskInStatusDone: (state, value) => (state.hasAddedTaskInStatusDone = value),
  setMyTasksResponsible: (state, value) => (state.myTasksResponsible = value),
  setLockSaving: (state, status) => (state.lockSaving = status),
  setChangeStatus: (state, payload) => {
    state.changeStatus = payload
  },
  setTask: (state, task) => {
    state.task = task
  },
  setBasicTeamsFromProject: (state, teams) => {
    state.basicTeamsFromProject = teams
  },
  setSelectedTeamsForTask: (state, teams) => {
    if (state.task && teams) {
      state.task.teams = teams
    }
  },
  setExpiredTaskId: (state, id) => {
    state.expiredTaskId = id
  },
  setAllTasks: (state, tasks) => {
    state.allTasks = tasks
  },
  setAssignedPerPage(state, value) {
    state.assignedPerPage = value
  },
  setResponsiblePerPage(state, value) {
    state.responsiblePerPage = value
  },
  setPerPage(state, value) {
    state.perPage = value
  },
  setTotalRows(state, value) {
    state.totalRows = value
  },
  setCurrentPage(state, page) {
    state.currentPage = page
  },
  addTasks(state, tasks) {
    state.allTasks.push(...tasks)
  },
  setTasksGantt: (state, tasksGantt) => {
    if (tasksGantt.tasks) {
      if (state.tasksGantt.data.length > 0) {
        tasksGantt.tasks.forEach(newTask => {
          const existingTaskIndex = state.tasksGantt.data.findIndex(task => task.id === newTask.id)
          if (existingTaskIndex !== -1) {
            state.tasksGantt.data[existingTaskIndex] = newTask
          } else {
            state.tasksGantt.data.push(newTask)
          }
        })
      } else {
        state.tasksGantt.data = tasksGantt.tasks
      }
      state.tasksGantt.data.sort((a, b) => a.sortorder - b.sortorder)
    } else {
      state.tasksGantt = tasksGantt
    }
  },
  setUpdatedTasksGantt: (state, updatedGantt) => {
    state.updatedGantt = updatedGantt
    state.tasksGantt.data.forEach((task, index) => {
      const updatedTask = updatedGantt.find(updatedTask => updatedTask.id === task.id)
      if (updatedTask) {
        state.tasksGantt.data[index] = updatedTask
      }
    })
  },
  setLinksGantt: (state, links) => {
    if (links.length) state.tasksGantt.links = links
  },
  setDiffInstruments(state, value) {
    if (!value) return
    state.diffInstruments = value
  },
  setFilters(state, filterObject) {
    localStorage.setItem('taskFilters', JSON.stringify(filterObject))
    state.filters = cloneDeep(filterObject)
  },
  resetFilters(state) {
    localStorage.removeItem('taskFilters')
    state.filters = initTaskFilters
  },
  setSearch: (state, value) => {
    localStorage.setItem('taskSearch', value)
    state.search = value
  },
  resetSearch(state) {
    localStorage.removeItem('taskSearch')
    state.search = ''
  },
  setSortGlobal: (state, value) => (state.sortGlobal = value),
  hasDelete: (state, value) => (state.hasDelete = value),
  updateTaskStatus(state, { task, newTasks }) {
    const updateTask = (tasks, taskId, newTask) => {
      for (let i = 0; i < tasks.length; i++) {
        const currentTask = tasks[i]
        if (currentTask.id === taskId) {
          tasks[i] = newTask
          return
        }
        if (currentTask.subtasks && currentTask.subtasks.length > 0) {
          updateTask(currentTask.subtasks, taskId, newTask)
        }
      }
    }

    const moveTaskToStatus = (taskId, newStatus) => {
      for (const statusName in state.tasks) {
        if (statusName === newStatus) {
          continue
        }
        const statusTasks = state.tasks[statusName]
        const taskIndex = statusTasks.findIndex(t => t.id === taskId)
        if (taskIndex !== -1) {
          statusTasks.splice(taskIndex, 1)
        }
      }
    }

    for (const taskId in newTasks) {
      const newTask = newTasks[taskId]
      if (newTask.state_id === null) {
        // Главная задача
        const oldStatusTasks = state.tasks[task.status.name]
        state.tasks[task.status.name] = oldStatusTasks.filter(t => t.id !== taskId)
        state.tasks[newTask.status.name].unshift(newTask)
        moveTaskToStatus(taskId, newTask.status.name)
      } else {
        // Задача в subtasks
        updateTask(state.tasks.open, taskId, newTask)
        updateTask(state.tasks.progress, taskId, newTask)
        updateTask(state.tasks.done, taskId, newTask)
        moveTaskToStatus(taskId, newTask.status.name)
      }
    }
  },
  setDelete(state, { task }) {
    // главная задача
    for (const status in state.tasks) {
      const tasks = state.tasks[status]

      if (task.state_id === null) {
        const taskIndex = tasks.findIndex(t => t.id === task.id)
        if (taskIndex !== -1) {
          state.tasks[status] = tasks.filter(t => t.id !== task.id)
        }
      } else {
        // задача в subtasks
        const deleteTaskIn = tasks => {
          for (let i = 0; i < tasks.length; i++) {
            const subtask = tasks[i]
            if (subtask.id === task.id) {
              tasks.splice(i, 1)
              return
            }
            if (Array.isArray(subtask.subtasks)) {
              deleteTaskIn(subtask.subtasks)
              if (subtask.subtasks.length === 0) {
                subtask.subtasks = []
              }
            }
          }
        }

        deleteTaskIn(tasks)
      }
    }
  },
  setDeleteInAllTasks(state, { task }) {
    // главная задача
    const tasks = state.allTasks
    if (task.state_id === null) {
      const taskIndex = tasks.findIndex(t => t.id === task.id)
      if (taskIndex !== -1) {
        tasks.splice(taskIndex, 1)
      }
    } else {
      // задача в subtasks
      const deleteTaskIn = tasks => {
        for (let i = 0; i < tasks.length; i++) {
          const subtask = tasks[i]
          if (subtask.id === task.id) {
            tasks.splice(i, 1)
            return
          }
          if (Array.isArray(subtask.subtasks)) {
            deleteTaskIn(subtask.subtasks)
            if (subtask.subtasks.length === 0) {
              subtask.subtasks = []
            }
          }
        }
      }

      deleteTaskIn(tasks)
    }
  },
  setNewTask: (state, { task }) => {
    if (task.state_id === null) {
      // главная задача
      const tasks = state.tasks[task.status.name]
      tasks.unshift(task)
    } else {
      // задача в subtasks
      const parentTask = findParentTask(state.tasks, task.state_id)
      if (!parentTask) return
      if (!parentTask.subtasks) {
        parentTask.subtasks = []
        parentTask.subtasks.unshift(task)
      } else {
        parentTask.subtasks.unshift(task)
      }
    }
  },
  setNewTaskInAllTasks: (state, { task }) => {
    if (task.state_id === null) {
      // главная задача
      const tasks = state.allTasks
      tasks.unshift(task)
    } else {
      // задача в subtasks
      const parentTask = findParentTask(state.allTasks, task.state_id)
      if (!parentTask) return
      if (!parentTask.subtasks) {
        parentTask.subtasks = []
        parentTask.subtasks.unshift(task)
      } else {
        parentTask.subtasks.unshift(task)
      }
    }
  },
  setDeleteGantt: (state, task) => {
    if (task) {
      state.tasksGantt.data = state.tasksGantt.data.filter(el => el.id !== task)
    }
  },
  setSubtasksTask: (state, task) => {
    if (state.task) {
      state.task.subtasks.push(task)
    }
  },
  setSubtasksGantt: (state, task) => {
    state.subtaskGantt = task
  },
  setMyTasksToday: (state, tasks) => {
    state.myTasksToday = tasks
  },
  setTasks: (state, options) => {
    if (options.mode === 'append' && options.status) state.tasks[options.status].push(...options.tasks)
    if (options.mode !== 'append' && options.status) {
      if (state.tasks[0]) state.tasks = {}
      state.tasks[options.status] = options.tasks
    }
    if (!options.status) state.tasks = options.tasks
  },
  updateTaskInList(state, payload) {
    const { id, data } = payload
    const updateItem = item => {
      for (const [key, value] of Object.entries(data)) {
        item[key] = Array.isArray(value) ? [...value] : value
      }
    }

    const updateTasks = tasks => {
      tasks.forEach(item => {
        if (item.id === id) {
          updateItem(item)
        }
        if (Array.isArray(item.subtasks)) {
          updateTasks(item.subtasks)
        }
      })
    }

    if (state.allTasks.length) {
      updateTasks(state.allTasks)
    }

    Object.values(state.tasks).forEach(tasks => {
      if (Array.isArray(tasks)) {
        updateTasks(tasks)
      }
    })
  },
  updateTasksTimes(state, { task, newTasks }) {
    const updateTaskTimesRecursively = tasks => {
      tasks.forEach(existingTask => {
        const taskId = existingTask.id

        if (newTasks[taskId]) {
          const newTask = newTasks[taskId]
          existingTask.plan_date = newTask.plan_date
          existingTask.deadline = newTask.deadline
        }

        if (existingTask.subtasks && existingTask.subtasks.length > 0) {
          updateTaskTimesRecursively(existingTask.subtasks)
        }
      })
    }

    const updateSubtaskTimes = subtasks => {
      subtasks.forEach(subtask => {
        if (newTasks[subtask.id]) {
          const newTask = newTasks[subtask.id]
          subtask.plan_date = newTask.plan_date
          subtask.deadline = newTask.deadline
        }

        if (subtask.subtasks && subtask.subtasks.length > 0) {
          updateSubtaskTimes(subtask.subtasks)
        }
      })
    }

    updateTaskTimesRecursively(state.tasks.open)
    updateTaskTimesRecursively(state.tasks.progress)
    updateTaskTimesRecursively(state.tasks.done)

    if (task.state_id !== null) {
      updateSubtaskTimes(state.tasks.open)
      updateSubtaskTimes(state.tasks.progress)
      updateSubtaskTimes(state.tasks.done)
    }
  },
  setHistoryTasksTMC: (state, tasks) => (state.historyTasksTMC = tasks),
  setStatusesTmc: (state, statusesTmc) => {
    state.statusesTmc = statusesTmc
  },
  setEquivalent: (state, equivalent) => {
    state.equivalentInstruments = equivalent
  },
  setProjects: (state, projects) => {
    state.projects = projects
  },
  setEquipments: (state, equipments) => {
    state.equipments = equipments
  },
  setPersonalList: (state, personalList) => {
    state.personalList = personalList
  },
  setTmcList: (state, tmcList) => {
    state.tmcList = tmcList
  },
  setSelectedTask: (state, value) => {
    state.selectedTask = value
  },
  setAddFormStepLocked(state, value) {
    state.addFormStepLocked = value
  },
  setTaskCardScrolledOut(state, value) {
    state.taskCardScrolledOut = value
  },
  setDateDynamicTaskGantt(state, value) {
    state.dateDynamicTaskGantt = value
  },
  setPaginationOnStatus: (state, options) => {
    state.taskPagination[options.key] = options.value
  },
  setCopyTaskGantt(state, value) {
    state.tasksGantt.data.push(...value)
  },
  setUpdateTasks: (state, task) => {
    for (const status in state.tasks) {
      if (!state.tasks[status].length) {
        continue // Пропускаем расчёт если задач нет
      }

      const tasks = state.tasks[status]

      if (task.state_id === null) {
        const taskIndex = tasks.findIndex(t => t.id === task.id)
        if (taskIndex !== -1) {
          tasks[taskIndex] = task
        }
      } else {
        // задача в subtasks
        const updateTaskIn = tasks => {
          for (let i = 0; i < tasks.length; i++) {
            const subtask = tasks[i]
            if (subtask.id === task.id) {
              tasks[i] = task
              return
            }
            if (Array.isArray(subtask.subtasks)) {
              updateTaskIn(subtask.subtasks)
            }
          }
        }

        updateTaskIn(tasks)
      }
    }
  },
  setSwitchMergeStatus: (state, status) => {
    state.isMergingTasksMode = status
  },
  setTaskToMergeList: (state, task) => {
    state.mergedTaskList.push(task)
  },
  removeTaskFromMergeList: (state, taskId) => {
    state.mergedTaskList = state.mergedTaskList.filter(task => task.id !== taskId)
  },
  resetTaskMergeList: state => {
    state.mergedTaskList = []
  },
  setCurrentPlanPage: (state, pageNumber) => {
    state.currentPagePlan = pageNumber
  },
  setCurrentDonePage: (state, pageNumber) => {
    state.currentPageDone = pageNumber
  },
  setCurrentProgressPage: (state, pageNumber) => {
    state.currentPageProgress = pageNumber
  },
  setCurrentVerifyPage: (state, pageNumber) => {
    state.currentPageVerify = pageNumber
  },
  setColorStatuses: (state, statuses) => {
    state.tasksColorStatuses = statuses
  },
}
