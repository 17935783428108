import { createApp } from 'vue'
// import './Utils/filter'
import App from './App.vue'
import * as Sentry from '@sentry/vue'
import router from './router'
import store from './store'
import axios from '@/store/axios'
// import Raphael from 'raphael/raphael'
// import './plugins'
// import './registerServiceWorker'

import VueViewer from 'v-viewer'
import moment from 'moment'
import { Vue3Mq } from 'vue3-mq'
import mqConfig from './mq/mq'
import toast from './plugins/toast'
import FloatingVue from 'floating-vue' // Нужно будет избавиться

import vClickOutside from './directives/v-click-outside'
import JsonExcel from 'vue-json-excel3'
import VueFullscreen from 'vue-fullscreen'
import SocketIO from 'socket.io-client'
import VueTippy from 'vue-tippy'
import contextPlugin from '@/Utils/context-plugin'
import registerGlobalComponents from '@/iconsGlobalRegestration'
import vSelect from 'vue-select'
import BootstrapVue from 'bootstrap-vue'

import 'viewerjs/dist/viewer.css'
import '@g/src/assets/styles/general.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'floating-vue/dist/style.css' // Нужно будет избавиться
import 'vue-select/dist/vue-select.css'

// window.Raphael = Raphael

function initializeApp() {
  moment.locale('ru')

  const app = createApp(App)

  window.io = SocketIO

  Sentry.init({
    app,
    dsn: 'https://056b9d743c7e2b3b63c90c34c6470b6b@sentry.event-horizon.systems/12',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: process.env?.VUE_APP_SENTRY_ENVIRONMENT || 'development',
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

  app.component('v-select', vSelect)
  app.component('modal', require('./components/bootstrap/modal/modal').default)
  app.component('model-header', require('./components/bootstrap/modal/model-header').default)
  app.component('model-body', require('./components/bootstrap/modal/model-body').default)
  app.component('model-footer', require('./components/bootstrap/modal/model-footer').default)
  app.component('iq-card', require('@/components/socialvue/cards/iq-card').default)

  app.component('downloadExcel', JsonExcel)
  registerGlobalComponents(app)

  // Vue.config.productionTip = false
  app.use(BootstrapVue)
  app.use(VueViewer)
  app.use(Vue3Mq, mqConfig)
  app.use(VueFullscreen)
  app.use(router)
  app.use(store)
  app.use(FloatingVue) // Нужно будет избавиться
  app.use(VueTippy)

  app.directive('click-outside', vClickOutside)

  app.config.globalProperties.$toast = toast
  app.use(contextPlugin)

  app.mount('#app')
}

function authMicro(authData) {
  const _authData = JSON.parse(authData)
  localStorage.setItem('token', _authData.token)
  localStorage.setItem('user', JSON.stringify(_authData.user))
  localStorage.setItem('accesses', JSON.stringify(_authData.accesses))
  localStorage.setItem('userRoles', JSON.stringify(_authData.user.roles))
  localStorage.setItem('routes', JSON.stringify(_authData.routes))
  localStorage.setItem('configs', JSON.stringify(_authData.configs))
  localStorage.setItem('micro', 'micro')
  axios.defaults.headers.common = { Authorization: 'Bearer ' + _authData.token }
  initializeApp()
}

window.addEventListener('message', event => {
  if (event.origin !== 'http://localhost:8082') return
  const { type, authData, route } = event.data
  if (type === 'AUTH_DATA' && authData) authMicro(authData)
  if (type === 'CHANGE_ROUTE' && route) router.push(route)
})

if (!localStorage.getItem('micro')) initializeApp()
