import axios from '../axios'

const setUpdatePauseGantt = (res, commit) => {
  if (res) {
    commit('setTask', res)
    const payload = {
      id: res.id,
      data: res,
    }
    commit('updateTaskInList', payload)
    commit('Gantt/updateTaskPauseGantt', res, { root: true })
    commit('Gantt/setTaskGanttPause', undefined, { root: true })
    commit('Gantt/setTaskWasUpdated', true, { root: true })
  }
}
export default {
  async taskShow({ commit, getters }, id) {
    // просмотр в карточке задачи
    try {
      const response = await axios.get(`/tasks/${id}`)
      if (getters.tasksGantt && getters.tasksGantt.data) {
        const ganttTask = getters.tasksGantt.data.find(el => el.id === response.id)
        response.type_gantt = ganttTask?.$rendered_type
      }
      commit('setTask', response)
      commit('setSelectedTask', response)
      return response
    } catch (e) {
      console.info(e)
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке рабочего задания произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async newTaskCollect({ commit }, payload) {
    // Создание задачи на укомплектовку
    const { data, id } = payload
    try {
      const response = await axios.post(`tasks/${id}/instruments/collect`, data)
      commit('setNewTask', { task: response })
      commit('Notify/setSuccess', 'Рабочее задания на укомплектовку инструмента успешно создана.', { root: true })
      return true
    } catch (e) {
      console.info(e)
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async newTaskCollectNewDesign({ commit }, payload) {
    // Создание задачи на укомплектовку
    const { data, id } = payload
    try {
      await axios.post(`tasks/${id}/instruments/collect`, data)
      commit('Notify/setSuccess', 'Рабочее задания на укомплектовку инструмента успешно создана.', { root: true })
      return true
    } catch (e) {
      console.info(e)
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadNewTaskData({ rootGetters, commit }, payload) {
    // Создание новой задачи
    const { data, parentStatus, isAddingInCard, isGantt } = payload
    try {
      const response = await axios.post('tasks', data)

      if (!isGantt) {
        commit('setNewTask', { task: response })
        commit('setNewTaskInAllTasks', { task: response })

        if (response.work_zone) {
          const currentConfig = rootGetters.configs
          const hasZone = currentConfig.work_zones.find(el => el.id === response.work_zone.id)

          if (!hasZone) {
            currentConfig.work_zones.push(response.work_zone)
            const newConfig = { ...currentConfig }
            commit('setConfigs', newConfig, { root: true })
          }
        }
      } else {
        if (isAddingInCard) {
          commit('Gantt/setGanttNewTask', response, { root: true })
        }
      }
      if (isAddingInCard) {
        commit('setSubtasksTask', response)
        commit('hasAddedTaskInStatusDone', parentStatus === 'done')
      }
      return response
    } catch (e) {
      console.error('uploadNewTaskData', e)
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
      return false
    }
  },
  // Создание новой задачи
  async uploadNewTaskDataNewDesign({ dispatch, commit }, payload) {
    const { data } = payload
    try {
      const response = await axios.post('tasks', data)
      commit('setNewTask', { task: response })
      commit('setSubtasksTask', response)
      dispatch('Task/updateTaskTree', { node: response }, { root: true })
      return response
    } catch (e) {
      console.error('uploadNewTaskData', e)
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
      return false
    }
  },
  async uploadTaskEquipment(_, payload) {
    const { task, data } = payload
    try {
      return await axios.put(`task/${task.id}/update/equipment`, data)
    } catch (e) {}
  },
  async uploadTaskTimes({ commit }, payload) {
    const { task, data } = payload
    try {
      const response = await axios.put(`tasks/change-date/${task.id}`, data)
      commit('updateTasksTimes', { task, newTasks: response })
    } catch (e) {}
  },
  async uploadTaskTimesNewDesign(_, payload) {
    const { task, data } = payload
    try {
      await axios.put(`tasks/change-date/${task.id}`, data)
    } catch (e) {}
  },
  async uploadTeamsFromProject({ commit }, payload) {
    try {
      const response = await axios.put(`/projects/${payload}`)
      commit(
        'setBasicTeamsFromProject',
        response?.teams.map(team => {
          return {
            display_name: team.display_name,
            id: team.id,
            name: team.name,
          }
        }),
      )
    } catch (error) {
      console.error(error)
    }
  },
  async uploadTaskForStatus({ commit, dispatch }, payload) {
    // Запрос на присвоение задаче статуса
    const { task, data, isMyDay, isGantt } = payload
    try {
      const response = await axios.put(`task/${task.id}/change-status`, data)
      if (!isGantt) dispatch('Task/updateTaskTree', { node: response[`${task.id}`] }, { root: true })
      commit('Gantt/setTaskWasUpdated', true, { root: true })
      if (!isMyDay) {
        commit('updateTaskStatus', { task, newTasks: response })
        if (isGantt) {
          dispatch('Gantt/ganttListTaskBranch', task.id, {root: true})
        }
      }

      for (const taskId in response) {
        if (taskId === task.id) {
          commit('setTask', response[taskId])
        }
      }
      return response
    } catch (e) {
      console.error(e)
      if (e.handled) return
      commit('Notify/setError', 'При смене статуса произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadTaskForStatusNewDesign({ commit }, payload) {
    // Запрос на присвоение задаче статуса
    const { task, data } = payload
    try {
      const response = await axios.put(`task/${task.id}/change-status`, data)
      return response[task.id]
    } catch (e) {
      console.info(e)
      if (e.handled) return
      commit('Notify/setError', 'При смене статуса произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadDeletedTask({ commit }, payload) {
    // удаление задач из списка
    const { task, isSearch } = payload
    const id = typeof task === 'string' ? task : task?.id
    try {
      await axios.delete(`tasks/${id}`)
      commit('hasDelete', true)
      if (typeof task !== 'string' && !isSearch) {
        commit('setDelete', { task })
      } else if (typeof task !== 'string' && isSearch) {
        commit('setDeleteInAllTasks', { task })
      } else {
        commit('setDeleteGantt', task)
      }
      return true
    } catch (e) {
      commit('Notify/setError', 'При удалении рабочего задания произошла ошибка. Попробуйте позже.', { root: true })
    }
  },

  async copyTask({ commit }, payload) {
    const { task, type } = payload
    try {
      const projectId = task.project_id ? task.project_id : task.project ? task.project.id : undefined

      const res = await axios.post(`task/${task.id}/clone`, {
        with_subtask: !!type,
        project_id: projectId,
      })
      if (res) {
        commit('setNewTask', { task: res })
      }
      return true
    } catch (e) {
      commit('Notify/setError', 'При копировании произошла ошибка. Попробуйте позже.', { root: true })
    }
  },

  async cloneTask({ commit }, payload) {
    const { withSubtask, projectId, isDraft, stateId, taskId } = payload

    try {
      const res = await axios.post(`task/${taskId}/clone`, {
        with_subtask: withSubtask,
        project_id: projectId,
        is_draft: isDraft,
        state_id: stateId,
      })
      if (res) {
        commit('setNewTask', { task: res })
      }
      return true
    } catch (e) {
      commit('Notify/setError', 'При копировании произошла ошибка. Попробуйте позже.', { root: true })
    }
  },

  async cloneTaskNewDesign({ commit }, payload) {
    const { withSubtask, projectId, isDraft, stateId, taskId } = payload

    try {
      await axios.post(`task/${taskId}/clone`, {
        with_subtask: withSubtask,
        project_id: projectId,
        is_draft: isDraft,
        state_id: stateId,
      })

      return true
    } catch (e) {
      commit('Notify/setError', 'При копировании произошла ошибка. Попробуйте позже.', { root: true })
    }
  },

  async downloadTaskList({ rootState, commit }, paramObj) {
    // Копируем параметры запроса
    let params = { ...paramObj }
    commit('tasksWasDownload', true)
    if (params['filter[projects][0]'] && params['filter[projects][0]'].includes(',')) {
      const projects = params['filter[projects][0]'].split(',')
      projects.forEach((project, index) => {
        params[`filter[projects][${index}]`] = project
      })
    }

    if (rootState.TaskList.sortGlobal) {
      params = { ...rootState.TaskList.sortGlobal, ...params }
    } else {
      const defaultSort = { 'filter[state]=1&sort[column]=created_at&sort[type]': 'desc' }
      params = { ...defaultSort, ...params }
    }

    if (params.initialize) {
      commit('setTasks', { mode: 'change', status: params['filter[statuses][0]'], tasks: [] })
      delete params.initialize
    }

    const param = Object.keys(params).reduce((acc, key) => {
      if (key !== 'mode') {
        acc += `${key}=${params[key]}&`
      }
      return acc
    }, '')

    try {
      const response = await axios.get(`tasks?${param}`)

      if (params['filter[statuses][0]'] === 'open') {
        commit('setCurrentPlanPage', params.page)
      }

      if (params['filter[statuses][0]'] === 'done') {
        commit('setCurrentDonePage', params.page)
      }

      if (params['filter[statuses][0]'] === 'progress') {
        commit('setCurrentProgressPage', params.page)
      }

      if (Object.prototype.hasOwnProperty.call(params, 'filter[statuses][0]')) {
        commit('setPaginationOnStatus', {
          key: `${params['filter[statuses][0]']}`,
          value: response.total_pages,
        })
      }
      commit('setTasks', { status: params['filter[statuses][0]'], tasks: response.data, mode: params.mode || '' })
      commit('tasksWasDownload', false)
      return response
    } catch (e) {
      console.log('e', e)
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка рабочих заданий произошла ошибка. Попробуйте позже.', {
          root: true,
        })
      }
    }
  },
  async myTasksResponsibleList({ commit }, payload) {
    try {
      const res = await axios.get(`my_tasks_today?${payload}`)
      commit('setMyTasksResponsible', res)
      return res
    } catch (e) {
      console.log('myTasksResponsible', e)
    }
  },
  // Редактирование задачи
  async uploadEditedTaskData({ commit }, payload) {
    const { id, data } = payload
    commit('taskHasUpdated', true)
    try {
      const response = await axios.put(`tasks/${id}`, {
        ...data,
        teams: data?.teams?.map(item => item.id) ?? undefined,
      })
      commit('setSelectedTask', response)
      commit('setUpdateTasks', response)
      commit('updateTaskInList', payload)
      commit('setSelectedTeamsForTask', payload?.teams)
      commit('Gantt/updateTaskGantt', response, { root: true })
      commit('Gantt/setTaskWasUpdated', true, { root: true })
      if (response) {
        setTimeout(() => {
          commit('taskHasUpdated', false)
        }, 500)
      }

      return response
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
      throw e
    }
  },
  async uploadEditedTaskDataNewDesign({ commit, dispatch }, payload) {
    // Редактирование задачи
    const { id, data } = payload
    try {
      const result = await axios.put(`tasks/${id}`, {
        ...data,
        teams: data?.teams?.map(item => item.id) ?? undefined,
      })
      dispatch('Task/updateTaskTree', { node: result }, { root: true })
      return result
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
      throw e
    }
  },
  async uploadProjectTaskGantt({ commit }, payload) {
    // Редактирование родительского проект в Ганте
    const { id, data } = payload
    try {
      const response = await axios.put(`tasks/${id}`, data)
      commit('setSelectedTask', response)
      return true
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
      throw e
    }
  },

  async downloadTaskListKanban({ rootGetters, commit }, paramObj) {
    delete paramObj.initialize
    if (!paramObj['filter[projects][0]']) delete paramObj['filter[projects][0]']
    const param = Object.keys(paramObj).reduce((acc, key) => (acc += `${key}=${paramObj[key]}&`), '')

    rootGetters.taskStatuses.forEach(status =>
      commit('setTasks', {
        mode: 'change',
        status: status?.name,
        tasks: [],
      }),
    )
    const tasks = await axios.get(`tasks/subtasks/list?${param}`)
    const tasksByStatus = {
      open: tasks.filter(task => task.status?.name === 'open'),
      progress: tasks.filter(task => task.status?.name === 'progress'),
      done: tasks.filter(task => task.status?.name === 'done'),
    }
    Object.keys(tasksByStatus).forEach(status => {
      commit('setTasks', {
        mode: 'change',
        status,
        tasks: tasksByStatus[status],
      })
    })
  },
  async downloadAllTasks({ commit, state }, paramObj) {
    state.loading = true
    const params = paramObj.params || paramObj
    let param = params.args ? `${params.args.replace('?', '')}&` : ''
    param = Object.keys(params).reduce((acc, key) => {
      if (key !== 'args' && key !== 'pagination') {
        acc += `${key}=${params[key]}&`
      }
      return acc
    }, param)

    param = param.endsWith('&') ? param.slice(0, -1) : param
    const tasks = await axios.get(`tasks?${param}`)
    if (paramObj.pagination) {
      state.allTasks.push(...tasks.data)
      commit('setAllTasks', state.allTasks)
    } else {
      commit('setAllTasks', tasks.data)
    }
    commit('setPerPage', tasks.total_pages)
    commit('setTotalRows', tasks.total)
    commit('setCurrentPage', tasks.current_page)
    state.loading = false
    return true
  },
  async downloadAllTasksWithSearchAndPagination({ commit }, paramObj) {
    let tasks = null

    const isPage = paramObj.includes('page') && !paramObj.includes('search')
    const isSearch = !paramObj.includes('page') && paramObj.includes('search')
    const isPageAndSearch = paramObj.includes('page') && paramObj.includes('search')

    if (isPage) {
      tasks = await axios.get(`tasks${paramObj}`)
      commit('addTasks', tasks.data)
      commit('setCurrentPage', tasks.current_page)
    } else if (isSearch) {
      tasks = await axios.get(`tasks${paramObj}&page=1`)
      commit('setAllTasks', tasks.data)
      commit('setPerPage', tasks.total_pages)
      commit('setCurrentPage', tasks.current_page)
    } else if (isPageAndSearch) {
      tasks = await axios.get(`tasks${paramObj}`)
      commit('addTasks', tasks.data)
      commit('setCurrentPage', tasks.current_page)
    } else {
      tasks = await axios.get('tasks?page=1')
      commit('setAllTasks', tasks.data)
      commit('setPerPage', tasks.total_pages)
      commit('setCurrentPage', tasks.current_page)
    }
  },
  async downloadNextPage({ commit }, paramObj) {
    const param = Object.keys(paramObj).reduce((acc, key) => (acc += `${key}=${paramObj[key]}&`), '')
    const response = await axios.get(`tasks?${param}`)
    commit('addTasks', response.data)
    commit('setCurrentPage', paramObj.page)
    return true
  },
  async downloadMyTaskToday({ commit }) {
    const paramObj = {
      'filter[kanban]': 1,
      'filter[types][0]': 'assigned',
    }
    const param = Object.keys(paramObj).reduce((acc, key) => (acc += `${key}=${paramObj[key]}&`), '')
    const tasks = await axios.get(`my_tasks_today?${param}`)
    commit('setMyTasksToday', tasks)
  },
  async downloadProjectList({ commit, state }, payload) {
    // Получение списка проектов
    let url = 'projects'

    if (payload) {
      if (payload.pagination || payload.search) {
        url += payload.args
      }
    }

    try {
      const response = await axios.get(url)
      const projects = response.data || []

      const updatedProjects =
        payload && payload.pagination
          ? [...state.projects, ...projects.filter(project => !state.projects.some(item => item.id === project.id))]
          : [...projects]

      commit('setProjects', updatedProjects)
    } catch (error) {
      console.error(error)
      if (!error.logout) {
        commit('Notify/setError', 'При загрузке списка проектов произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async downloadEquipmentList({ commit, state }, payload) {
    // Получение списка оборудования
    let url = 'repairs/equipment/list'

    if (payload) {
      if (payload.pagination || payload.search) {
        url += payload.args
      }
    }

    try {
      const response = await axios.get(url)
      const equipments = response.data || []

      const updatedEquipments =
        payload && payload.pagination
          ? [
              ...state.equipments,
              ...equipments.filter(equipment => !state.equipments.some(item => item.id === equipment.id)),
            ]
          : [...equipments]

      commit('setEquipments', updatedEquipments)
    } catch (error) {
      console.error(error)
      if (!error.logout) {
        commit('Notify/setError', 'При загрузке списка оборудования произошла ошибка. Попробуйте позже.', {
          root: true,
        })
      }
    }
  },
  async downloadInstrumentsEquivalent({ commit, rootGetters }, payload) {
    // Получение списка инструментов для подбора эквивалента
    const statuses = rootGetters.configs.statuses
    const inModule = statuses.find(el => el.name === 'in_module')
    const use = statuses.find(el => el.name === 'use')
    const issued = statuses.find(el => el.name === 'issued')

    const search = payload ? `&search=${payload}` : ''
    try {
      const res = await axios.get(
        `tasks/instrument/list?filter[statuses][0]=${inModule.id}&filter[statuses][1]=${use.id}&filter[statuses][2]=${issued.id}${search}`,
      )
      commit('setEquivalent', res || [])
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async downloadTaskUsagePersonal({ commit }, taskId) {
    // Получение списка персонала в задаче
    try {
      const res = await axios.get(`tasks/${taskId}/usage/users`)
      commit('setPersonalList', res || [])
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async downloadTaskUsageTmc({ commit }, taskId) {
    // Получение списка использованных инструментов в задаче
    try {
      const res = await axios.get(`tasks/${taskId}/usage/instruments`)
      commit('setTmcList', res || [])
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async downloadTaskListHistoryTmc({ commit }) {
    // Получение списка задач для страницы истории применения МТР
    try {
      const { data } = await axios.get('tasks/instrument/history')
      commit('setHistoryTasksTMC', data)
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка рабочих заданий произошла ошибка. Попробуйте позже.', {
          root: true,
        })
      }
    }
  },

  async downloadTaskListHistoryCurrentTmc({ commit }, id) {
    // Получение списка задач для конкретного МТР
    try {
      const { data } = await axios.get(`instruments/${id}/history/tasks`)
      commit('setHistoryTasksTMC', data)
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка рабочих заданий произошла ошибка. Попробуйте позже.', {
          root: true,
        })
      }
    }
  },
  async downloadTaskListStatusesTmc({ commit }, id) {
    // Получение статусов для конкретного МТР
    try {
      const { data } = await axios.get(`instruments/${id}/history/statuses`)
      commit('setStatusesTmc', data)
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке статусов произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async assignedTaskList({ commit }, payload) {
    const { user } = payload
    const param = Object.keys(payload).reduce((acc, key) => {
      if (key !== 'user') {
        acc += `${key}=${payload[key]}&`
      }
      return acc
    }, '')
    try {
      const res = await axios.get(`/tasks?filter[assigned][0]=${user}&${param}`)
      commit('setAssignedPerPage', res.total_pages)
      return res.data
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка рабочих заданий произошла ошибка. Попробуйте позже.', {
          root: true,
        })
      }
    }
  },
  async responsibleTaskList({ commit }, payload) {
    const { user } = payload
    const param = Object.keys(payload).reduce((acc, key) => {
      if (key !== 'user') {
        acc += `${key}=${payload[key]}&`
      }
      return acc
    }, '')

    try {
      const res = await axios.get(`/tasks?filter[responsible][0]=${user}&${param}`)
      commit('setResponsiblePerPage', res.total_pages)
      return res.data
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка рабочих заданий произошла ошибка. Попробуйте позже.', {
          root: true,
        })
      }
    }
  },
  async downloadUserInstrumentHistory({ commit }, id) {
    // список выданных МТР в профиле пользователя
    try {
      const { data } = await axios.get(`users/${id}/instruments?status=issued`)
      return data
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка рабочих заданий произошла ошибка. Попробуйте позже.', {
          root: true,
        })
      }
    }
  },
  async downloadUserDisposedInstruments({ commit }, id) {
    // список утилизированных МТР в профиле пользователя
    try {
      const { data } = await axios.get(`/users/${id}/disposed/instruments`)
      return data
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка рабочих заданий произошла ошибка. Попробуйте позже.', {
          root: true,
        })
      }
    }
  },
  async getDiffInstruments({ commit }, taskId) {
    // получение разницы между кол-вом инструментов, указанных в задаче и фактическим кол-вом на складе
    try {
      if (!taskId) return
      const res = await axios.get(`tasks/${taskId}/fill/module`)
      commit('setDiffInstruments', res)
      return true
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async getDiffInstrumentsNewDesign({ commit }, taskId) {
    // получение разницы между кол-вом инструментов, указанных в задаче и фактическим кол-вом на складе
    try {
      await axios.get(`tasks/${taskId}/fill/module`)
      return true
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async downloadUserLostInstruments({ commit }, id) {
    try {
      const { data } = await axios.get(`/users/${id}/lost/instruments`)
      return data
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка рабочих заданий произошла ошибка. Попробуйте позже.', {
          root: true,
        })
      }
    }
  },

  async detachFiles({ commit }, taskId) {
    // открепление файла от задачи

    try {
      await axios.post(`tasks/${taskId}/detach/files`)
      return true
    } catch (e) {
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },

  async uploadNewComment({ commit }, model) {
    try {
      const response = await axios.post('comments', model)
      commit('setTask', response)
      return true
    } catch (e) {
      console.info(e)
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
      return false
    }
  },

  async uploadNewCommentNewDesign({ commit }, model) {
    try {
      await axios.post('comments', model)
      return true
    } catch (e) {
      console.info(e)
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
      return false
    }
  },

  async downloadCommentsList({ commit }) {
    try {
      await axios.get('comments')
      return true
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке комментариев произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async uploadEditedComment({ commit }, payload) {
    const { id, model } = payload
    try {
      return await axios.put(`comments/${id}`, model)
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
      throw e
    }
  },
  async uploadDeletedComment({ commit }, id) {
    try {
      await axios.delete(`comments/${id}`)
      return true
    } catch (e) {
      console.log('e', e)
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },

  async taskPause({ commit }, id) {
    try {
      const res = await axios.put(`tasks/${id}/pause`)
      setUpdatePauseGantt(res, commit)
      return true
    } catch (e) {
      commit('Notify/setError', 'Не удалось поставить на паузу. Попробуйте позже.', { root: true })
      throw e
    }
  },
  async taskPlay({ commit }, id) {
    try {
      const res = await axios.put(`tasks/${id}/play`)
      setUpdatePauseGantt(res, commit)
      return true
    } catch (e) {
      commit('Notify/setError', 'Не удалось снять с паузы. Попробуйте позже.', { root: true })
      throw e
    }
  },
  async verifyTaskStatus(_, payload) {
    const { taskId, statusId } = payload
    try {
      return await axios.get(`/task/${taskId}/change-status/verification`, {
        params: {
          status_id: statusId,
        },
      })
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async mergeTasks(_, payload) {
    try {
      return await axios.post('tasks/merge', {
        name: payload.name,
        tasks_id: payload.tasksId,
      })
    } catch (error) {
      console.error(error)
    }
  },
  async getCriticalPathTasks(_, payload) {
    try {
      return await axios.get(`gantt/get_list_task_on_critical_path?projects[0]=${payload}`)
    } catch (error) {
      console.error(error)
    }
  },
}
