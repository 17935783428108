export default {
  columns: state => state.columns,
  columnsForInspector: state => state.columnsForInspector,
  columnsForForeman: state => state.columnsForForeman,
  shownColumns: state => state.columns.filter(column => column.fixed || column.show),
  shownColumnsForInspector: state => state.columnsForInspector.filter(column => column.fixed || column.show),
  shownColumnsForForeman: state => state.columnsForForeman.filter(column => column.fixed || column.show),
  instruments: state => state.instruments,
  instrument: state => state.instrument,
  scannedRfidMark: state => state.scannedRfidMark,
  instrumentsWithoutRfid: state => state.instrumentsWithoutRfid,
  instrumentWithoutRfid: state => state.instrumentWithoutRfid,
  usersWithPasses: state => state.usersWithPasses,
  needReloadInstruments: state => state.needReloadInstruments,
  perPage: state => state.perPage,
  totalRows: state => state.totalRows,
  scannedInstruments: state => state.scannedInstruments,
  selectedInstrumentType: state => state.selectedInstrumentType,
  selectedInstrument: state => state.selectedInstrument,
  needOpenModalForNewInstrument: state => state.needOpenModalForNewInstrument,
  addedInstrument: state => state.addedInstrument,
  search: state => state.search,
  userJunk: state => state.userJunk,
  instrumentShow: state => state.instrumentShow,
}
