import axios from '../axios'

export default {
  async downloadListWorkAreas({ commit }, payload) {
    try {
      const response = await axios.get(`/admin/work-zones${payload.args}`)
      commit('setWorkAreas', response.data)
      commit('setPerPage', response.per_page)
      commit('setTotalRows', response.total)
    } catch (e) {
      if (e.handled || e.logout) return
      console.log('e', e)
      commit('Notify/setError', 'При загрузке списка произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadNewWorkArea({ commit }, model) {
    try {
      const response = await axios.post('/admin/work-zones', model)
      commit('setSelectedWorkArea', {
        ...response,
      })
      commit('setConfigWorkZones', response, { root: true })
      commit('Notify/setSuccess', 'Зона успешно добавлена', { root: true })
      return true
    } catch (e) {
      if (e.handled || e.logout) return
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadDeletedWorkArea({ state, commit }) {
    try {
      const id = state.selectedWorkArea.id
      await axios.delete(`/admin/work-zones/${id}`)
      const updatedWorkAreas = state.workAreas.filter(area => area.id !== id)
      commit('setWorkAreas', updatedWorkAreas)
      commit('setRemoveConfigWorkZones', id, { root: true })
      return true
    } catch (e) {
      if (e.handled || e.logout) return
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadEditedWorkArea({ state, commit }, payload) {
    try {
      const { id, model } = payload
      const response = await axios.put(`/admin/work-zones/${id}`, model)
      const updatedWorkArea = {
        ...state.selectedWorkArea,
        ...response,
      }
      state.workAreas.push(response)

      commit('Notify/setSuccess', 'Изменения сохранены', { root: true })
      commit('setSelectedWorkArea', updatedWorkArea)
      commit('setWorkAreas', state.workAreas)
      return true
    } catch (e) {
      if (e.handled || e.logout) return
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
}
