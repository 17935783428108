export default {
  setCurrentPage: (state, pageNumber) => {
    state.currentPage = pageNumber
  },
  setTaskPageData: (state, data) => {
    state.currentPage = data.current_page
    state.count = data.count
    state.totalPages = data.total_pages
    state.total = data.total
    state.perPage = data.per_page
  },
  setTaskPageDataForScroll: (state, data) => {
    state.kanbanTotal = data.total
    state.kanbanCurrentPage = data.current_page
  },
  setTaskLoading: (state, data) => {
    state.isTasksLoading = data
  },
  setTaskList: (state, data) => {
    if (data) {
      state.taskList = data
    }
  },
  setSubTaskList: (state, data) => {
    if (data) {
      state.taskList = [...state.taskList, ...data]
    }
  },
  setKanbanTaskList: (state, payload) => {
    const { data, hasSearch } = payload
    if (data) {
      if (hasSearch) {
        state.taskListKanban = data
      } else {
        state.taskListKanban = [
          ...state.taskListKanban,
          ...data.filter(task => !state.taskListKanban.some(item => item.id === task.id)),
        ]
      }
    }
  },
  updateTaskList: (state, data) => {
    state.taskList = [...data, ...state.taskList.filter(task => task.parentId === null)]
  },
  updateSubTaskList: (state, data) => {
    data = data.map(item => ({ ...item, parent: item.state_id }))
    state.taskList = [...data, ...state.taskList.filter(task => !data.find(newTask => task.id === newTask.id))]
  },
  setTaskDeleteLoading: (state, data) => {
    state.isTaskDeleteLoading = data
  },
  deleteTask: (state, task) => {
    state.taskList.forEach(() => {
      // Если не главная задача
      if (task.state_id) {
        deleteTaskInSubtasks(state.taskList)
      } else {
        const taskIndex = state.taskList.findIndex(t => t.id === task.id)

        if (taskIndex !== -1) {
          state.taskList.splice(taskIndex, 1)
        }
      }
    })

    function deleteTaskInSubtasks(tasks) {
      for (let i = 0; i < tasks.length; i++) {
        const subtask = tasks[i]

        if (subtask.id === task.id) {
          tasks.splice(i, 1)
          return
        }

        if (Array.isArray(subtask.subtasks)) {
          deleteTaskInSubtasks(subtask.subtasks)
          if (subtask.subtasks.length === 0) {
            subtask.subtasks = []
          }
        }
      }
    }
  },
  setColorStatuses: (state, statuses) => {
    state.tasksColorStatuses = statuses
  },
  setShowGantt: (state, value) => (state.isShowGantt = value),
  setFilterResponsibleIsChecked: (state, value) => (state.filterResponsibleIsChecked = value),
}
