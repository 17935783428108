export default {
  setWorkAreas(state, value) {
    state.workAreas = value
  },
  setPerPage(state, value) {
    state.perPage = value
  },
  setTotalRows(state, value) {
    state.totalRows = value
  },
  setNeedOpenModalForNewWorkArea(state, value) {
    state.needOpenModalForNewWorkArea = value
  },
  setSelectedWorkArea(state, value) {
    state.selectedWorkArea = value
  },
}
